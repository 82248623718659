"use client";

import * as Sentry from "@sentry/nextjs";
import { useEffect } from "react";

import { Error } from "@/components/layout/error";

export default function ErrorBoundary({ error }) {
  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  return <Error />;
}
