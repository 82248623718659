import { EAP_SLACK_URL } from "@/constants/contact";

export const Error = () => {
  return (
    <div className="flex flex-col items-center justify-center flex-1 gap-2 w-full p-8 text-center">
      <h1 className="text-lg font-semibold">Unexpected error</h1>
      <p>
        Reload the current page to see if the error resolves. Otherwise, please
        {` `}
        <a
          href={EAP_SLACK_URL}
          target="_blank"
          rel="noreferrer"
          className="underline"
        >
          contact us
        </a>
        .
      </p>
    </div>
  );
};
